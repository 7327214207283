import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch, useSelector } from 'react-redux';
import { Listing_text } from '../../constant/Translation/Listing';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { HomeCardBoxDefaultStyles } from '../../constant/default_styles/HomeDefaultStyles';
import ListingDynamicSkeleton from '../Shared/skeletonUI/Listing/ListingDynamicSkeleton';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { removeKeysFromObject } from '../../utils/keyRemoveFromObject';
import {
  dynamicPageSelector,
  getDynamicPageSection,
  updateListingViewType,
} from '../../store/feature/Pages/DynamicPage/dynamicPageSlice';
import Warning from '../Shared/PopUp/Warning';
import PaginationTypeListings from './PaginationTypeListings';
import ScrollTypeListings from './ScrollTypeListing';
import ListingsView from './ListingsView';
import ListingsInMap from './ListingsInMap';
import ListingsInMapView from './ListingsInMapView';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import { EXTRA_QUERY_KEYS } from '../../data/ExtraQueryKeys';

const AllListingsWithMap = ({
  section_details,
  all_listings_data,
  styles,
  AllListingFilter,
}) => {
  const { fetch_by, extra_data } = section_details || {};
  const router = useRouter();
  const dispatch = useDispatch();

  const { is_fetching, isLoadedOnce } = useSelector(dynamicPageSelector);

  //
  const { dynamic_page_sections, isSingleSectionFetching, listing_view_type } =
    useSelector(dynamicPageSelector);

  //get more listings by page
  const moreListings = (current_page) => {
    dispatch(
      getDynamicPageSection({
        sections_data: [
          {
            ...section_details,
            fetch_by: {
              ...fetch_by,
              ...AllListingFilter,
              ...router.query,
              page: current_page,
            },
          },
        ],
      })
    );
  };

  // fetch listings base on category

  useEffect(() => {
    const filter_query_params = removeKeysFromObject({
      object_params: router.query,
      key_to_remove: ['draft', 'iframe', 'page_slug', ...EXTRA_QUERY_KEYS],
    });

    //
    if (
      (Object.keys(AllListingFilter)?.length > 0 ||
        Object.keys(filter_query_params)?.length > 0) &&
      isLoadedOnce
    ) {
      dispatch(
        getDynamicPageSection({
          sections_data: [
            {
              ...section_details,
              extra_data: {
                ...section_details.extra_data,
                fetching_type: 'fetching_by_effect',
              },
              fetch_by: {
                ...fetch_by,
                ...AllListingFilter,
                ...filter_query_params,
              },
            },
          ],
        })
      );
    }
  }, [AllListingFilter, router.query]);

  //

  const listing_box_style = useMemo(() => {
    return listing_view_type === 'map_view'
      ? styles?.with_map_styles
      : styles?.without_map_styles;
  }, [listing_box_style]);

  //
  useEffect(() => {
    if (extra_data?.listing_view_type) {
      dispatch(updateListingViewType(extra_data?.listing_view_type));
    }
  }, [extra_data]);

  return (
    <div className="w-full   " style={get_dynamic_styles(styles?.main_styles)}>
      {/* Linstings part */}
      {all_listings_data?.listings?.length === 0 && (
        <Warning
          className={' max-w-sm px-2 mx-auto'}
          message={Listing_text.productlist.no_products_found}
        />
      )}
      <div
        style={{
          display:
            listing_view_type === 'map_view'
              ? styles?.with_map_styles?.display
              : styles?.without_map_styles?.display,
          gridTemplateColumns:
            listing_view_type === 'map_view'
              ? styles?.with_map_styles?.columns_box_percentage
              : dynamic_text_convert({
                  main_text: styles?.without_map_styles?.columns_box_percentage,
                  check_by: '-1%',
                  value: '',
                }),
          ...get_dynamic_styles(
            listing_view_type === 'map_view'
              ? styles?.with_map_styles
              : styles?.without_map_styles
          ),
        }}
      >
        {/*  */}
        {all_listings_data !== null &&
        isSingleSectionFetching !== section_details?.block ? (
          <PaginationTypeListings
            all_listings_data={all_listings_data}
            dynamic_page_sections={dynamic_page_sections}
            section_details={section_details}
            moreListings={moreListings}
          />
        ) : (
          <ListingDynamicSkeleton
            listings_type={'list'}
            listing_card_type={
              TYPE_CONSTANT.STYLES_CONFIGS?.listing_card?.card_type?.type
            }
            per_page={fetch_by?.per_page}
          />
        )}

        {listing_view_type === 'map_view' && (
          <ListingsInMapView
            all_listings_data={all_listings_data}
            styles={styles}
          />
        )}
      </div>
    </div>
  );
};

export default AllListingsWithMap;
